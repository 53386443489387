import neoAnalyse from "@/components/analyse";
// import { object } from "underscore";
import neoSwitch from "@/components/toggle-switch";
// import { getMonitoring } from "@/utils/functions";
import {get} from "lodash";
import {mapGetters} from "vuex";
import axios from "@/axios";
import adverseMixin from "@shared/tools/mixins/adverseMixin";
import {EventBus} from "@/main.js";
import {getBackgroundStatus} from "@shared/utils/monitoringTools.js";
import {DateTime} from "luxon";
import Loader from "@/components/loader";
import CanvasMenu from "@shared/saas-components/canvas-menu";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "@shared/light-ray/assets/svg/check.svg";
import Question from "@shared/light-ray/assets/svg/question.svg";
import CrossRed from "@shared/light-ray/assets/svg/cross-red.svg";
export default {
    components: {
        confirmed: Check,
        unknown: MinusIcon,
        potential: Question,
        irrelevant: CrossRed,
        neoAnalyse,
        neoSwitch,
        "neo-loader": Loader,
        CanvasMenu
    },
    mixins: [adverseMixin],
    props: {
        riskCategoriesOptions: Array,
        mainEntity: Array,
        result: {
            type: Object,
            default: () => {},
        },
        selected: {
            type: Number,
            default: null,
        },
        index: {
            type: Number,
            default: null,
        },
        parentIndex: {
            type: Number,
            default: null,
        },
        isResultExpanded: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isAllRunQueries: {
            type: Boolean,
            default: false,
        },
        // getMonitoring: {
        //     type: Boolean,
        //     default: false,
        // },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showList: false,
            individualData: {},
            openDetails: false,
            // resultSave: false,
            monitoringHistoryIndex: null,
            allCardDetails: {},
        };
    },
    methods: {
        // getMonitoring,
        getStatusClass(status) {
            let statusClass = "rounded-full cursor-pointer p-1.5 h-7 w-7"
            if (status == 'potential' || status == 'irrelevant') {
                statusClass = "bg-red-100 " + statusClass
            }
            else if (status == 'unknown' || status == 'None') {
                statusClass = "bg-gray-300 " + statusClass
            }
            else {
                statusClass = "bg-green-50 " + statusClass
            }
            return statusClass
        },
        toogleDropdown() {
            this.showList = !this.showList;
        },
        // checkCategories(item, result) {
        //     let risk = this.riskCategoriesOptions.find(v => v.category == item);
        //     if (risk) {
        //         return risk.category_type === 'Risk Categories' ? 
        //             ((result.entity_status === 'Amber') ? 'amberLabel' : 
        //                 'redLabel') : 
        //             'greenLabel';
        //     }
        // },
        closeDropdown() {
            this.showList = false;
        },
        async selectDropdown(event) {
            this.showList = false;
            let key = event.target.innerText.toUpperCase();
            if (key === 'UNKNOWN') {
                key = "None"
            }
            if (this.result.card_status === key || 
                ((!this.result.card_status || this.result.card_status === "UNKNOWN") && !key)
            ) return;
            else {
                let url;
                let payload = {
                    case_id: this.getNeoCaseId,
                    card_id: this.result.api_all_data.doc_id,
                    entity: this.result.name,
                    query_id: this.$route.query.query_id,
                    tool_name: this.$route.query.check_name,
                    status: key,
                    main_entity_id: this.$route.query.entityId,
                    card_type: this.result.entity_type=='Individual' ? "person": "company",
                    source_name: "lexis",
                }
                url = `/service/upsert-card-result`;
                await axios.post(url, payload);  
                this.$toast.success("Successfully Updated");
                this.result.card_status = key
                this.updateCardStatus();
            }
        },
        get,
        parseNames(data) {
            return data.map((e) => e.name).join(", ");
        },
        parseTypes(data) {
            if (data?.trim()) return data?.split(",")?.map((e) => e.replace(/-/g, " ")?.replace(/None/g, "Others"));
            else return [];
        },
        parseDate(dt, format = "EEEE, dd LLLL yyyy") {
            if (dt) return DateTime.fromISO(dt).toFormat(format);
            else return "";
        },
        // expandResult(result, curr_index, index) {
        //     this.$emit("expandResult", result, curr_index, index);
        // },
        fetchSource(key) {
            if (key === "dowJones") {
                return this.result.api_all_data?.sources?.map((src) => `DJ-${src}`.toUpperCase());
            } else return [key];
        },
        async handleMonitoring() {
            // event.stopPropagation();
            let card = this.result;
            card.api_all_data.monitoring_status = !card.api_all_data.monitoring_status;
            let query;
            if (this.isAllRunQueries) {
                query = this.result.query;
            } else {
                query = this.getSelectedToolQuery;
            }
            try {
                await axios.post("/service/data-monitoring", {
                    product_name: this.getProduct.name,
                    product_id: this.getProduct.key,
                    case_id: this.getNeoCaseId,
                    query: query,
                    tool: this.$route.query.check_name,
                    frequency: 7,
                    monitoring_type: "card",
                    inherit_product_settings: false,
                    inherit_product_id: "",
                    card_id: this.result.api_all_data.doc_id,
                    card_source: "lexis",
                    status: card.api_all_data.monitoring_status ? "enabled" : "disabled",
                });
                this.$toast.success("Monitoring updated successfully !");
            } catch (error) {
                this.$toast.error("Something Went Wrong !");
            }
            // EventBus.$emit("handleDone", {card: this.result, curr_index: card.curr_index, oldVersion});
            this.$forceUpdate();
        },
        getResultsID(index) {
            return `result-${index}`;
        },
        // changedResult(og_value, key, classes = false) {
        //     let result = {};
        //     let changeKey = this.get(this.result.api_all_data.change_history, key, []);
        //     if (changeKey.length) {
        //         result = this.getMonitoring(changeKey, og_value);
        //         return result;
        //     } else return { text: og_value };
        // },
        async handleDone({target}) {
            this.result.api_all_data.changes_viewed = !this.result.api_all_data.changes_viewed;
            // EventBus.$emit("handleDone", {card: this.result, curr_index: this.result.curr_index, oldVersion});

            let payload = [
                {
                    doc_id: this.result.api_all_data.doc_id,
                    sources: ["lexis"],
                    cascaded: false,
                },
            ];
            try {
            await axios.post("/service/mark-monitered-data-visited", {
                card_info: payload,
                case_id: this.getNeoCaseId,
                visited: target.checked,
            });
            }
            catch (err) {
                console.log(err)
            }

            this.updateComparisonReferences(this.result);
            setTimeout(()=>{
                this.$emit('getQueriesMonitoring')
                this.$toast.success('Card Acknowledged')
            }, 1000)
            
        },
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
        closeCanvas() {
            this.openDetails = false
        },
        updateCardStatus() {
            this.$emit("handleStatusUpdate")
        },
        showCardDetails() {
            this.openDetails = true
            this.allCardDetails = {
                entity_identifier: this.result.entity_type=='Individual' ? "name" : "company",
                case_id: this.getNeoCaseId,
                card_id: this.result.api_all_data.doc_id,
                entity: this.result.name,
                query_id: this.$route.query.query_id,
                tool_name: this.$route.query.check_name,
                status: this.result.card_status,
                main_entity_id: this.$route.query.entityId,
                card_type: this.result.entity_type=='Individual' ? "person": "company",
                relations: [],
                source_name: "lexis",
                notes: this.result.research_notes,
                customer_notes: this.result.customer_notes,
                risk_categories: this.result.risk_categories,
                bespoke: this.result.bespoke,
                entity_status: this.result.entity_status,
                screenshot: this.result.screenshot
            }
            // if (!this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex != this.result.api_all_data.doc_id) EventBus.$emit("handleResultSave", this.result);
        },
        getBackgroundStatus,

        showMonitoringHistory(index) {
            if (index !== this.monitoringHistoryIndex) this.monitoringHistoryIndex = index;
            else this.monitoringHistoryIndex = null;
        },

        updateComparisonReferences(data) {
            EventBus.$emit("updateMonitoringCascadingTimeline", {
                newData: data,
            });
        },
        getEntityType(result) {
            const type = result.entity_type;
            const key = result.key;
            if (key === "lexisBridger") {
                if (type === "Individual") return type;
                else return "Business";
            } else {
                return type;
                // if (type === "Individual" || ) return "Person";
                // else return "Company";
            }
        },
        getEntitySource(result) {
            if (result.entity_type.toLowerCase() === "person" || result.entity_type.toLowerCase() === "individual" || result.entity_type.toLowerCase() === "officer") return "name";
            else if (result.api_all_data.e_i && (result.api_all_data.e_i.toLowerCase() === "male" || result.api_all_data.e_i.toLowerCase() === "female")) return "name";
            else return "company_name";
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getCaseId", "getNeoCaseId", "getSelectedToolQuery", "getUserFeatures"]),
        monitoringAllowed() {
            return this.getUserFeatures.includes("adverse_media_pep_sanctions__monitoring")
        },
        getCardID() {
            if (this.parentIndex || this.parentIndex === 0) return `result-${this.parentIndex}.${this.index}`;
            return `result-${this.index}`;
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        getResultClasses() {
            let classes = "";
            if (this.isOldVersionSelected) {
                if (this.result.curr_index === this.selected && this.isOldVersion) classes += " bg-blue-50 border-blue-600 hover:border-blue-200 shadow-md ";
                else classes += " shadow bg-white hover:border-blue-200 hover:bg-blue-50 ";
            } else {
                if (this.result.curr_index === this.selected && !this.isOldVersion) classes += " bg-blue-50 border-blue-600 hover:border-blue-200 shadow-md ";
                else classes += " shadow bg-white hover:border-blue-200 hover:bg-blue-50 ";
            }
            if (this.result?.api_all_data?.hasChanges) classes += "border-green-500 border-3 shadow bg-white hover:bg-blue-50 hover:border-blue-600";
            else classes += " border ";
            // if (!this.result?.api_all_data?.history_count && !this.isOldVersion && this.isMonitoringTab)
            //     classes += ' ml-2';
            if (this.isMonitoringTab) {
                classes += "py-8";
            }
            return classes;
        },
        getSavedClasses() {
            let classes = "";
            if (this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex === this.result.api_all_data.doc_id) classes += " opacity-40 cursor-not-allowed ";
            if (this.result.api_all_data.saved) classes += " text-blue-700 font-semibold  text-xs bg-blue-200 ";
            else classes += " bg-blue-200 text-blue-800 ";
            return classes;
        },
    },
};
